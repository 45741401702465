<template>
  <div class="display">
    <p class="display-title">基本信息</p>
    <div class="display-middle">
      <icon-unit
        titleKey="机构名称"
        :titleValue="hospitalTitle"
        iconKey="机构Logo"
        :iconURL="hospitalLogo"
      ></icon-unit>
     
    </div>
  </div>
</template>

<script>
import IconUnit from "@c/setting/organization-configuration/display/icon-unit.vue";
import InfoUnit from "@c/setting/organization-configuration/display/info-unit/info-unit.vue";

export default {
  components: {
    IconUnit,
    InfoUnit,
  },

  props: {
    model: Object,
  },

  computed: {
    hospitalTitle() {
      return this.model.hospitalName;
    },

    hospitalLogo() {
      if (this.model.hospitalLogo == undefined) {
        return require("@imgs/logo-placeholder.png");
      } else {
        return this.model.hospitalLogo;
      }
    },

    reportTitle() {
      return this.model.reportTitle;
    },
    reportSubtitle(){
      return this.model.reportSubtitle;
    },

    reportLogo() {
      if (this.model.reportLogo == undefined) {
        return require("@imgs/logo-placeholder.png");
      } else {
        return this.model.reportLogo;
      }
    },

    standardTitle() {
      return findKey(CHILD_STANDDARD_TYPE_CODE, this.model.reportStandard);
    },

    suggestionTitle() {
      return findKey(REPORT_SUGGESTION_TYPE, this.model.reportEvaluate);
    },
  },
};
</script>

<style lang="scss" scoped>

.display {
  background-color: white;
  width: 100%;
  position: relative;

  &-title {
    color: black;
    font-size: rem(16);
    font-weight: bold;
    height: rem(22);
    margin-top: rem(16);
  }

  &-middle {
    margin-top: rem(18);
    display: flex;

    &-right {
      position: absolute;
      left: rem(618);
    }
  }


}
</style>